import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserInfo from "./page/UserInfo";
import TIPITest from "./page/TIPITest";
import TIPIResult from "./page/TIPIResult";

import './App.css';

function App() {
	return (
		<main className="App">
			<BrowserRouter>
					<Routes>
						<Route exact={true} path="/" element={<UserInfo />} />
						<Route exact={true} path="/tipi/test" element={<TIPITest />} />
						<Route exact={true} path="/tipi/result" element={<TIPIResult />} />
					</Routes>
			</BrowserRouter>
		</main>
	);
}

export default App;
