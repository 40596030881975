import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import './TIPITest.css';
import * as data from './Data';

const makeQuestList = () => {
	const noList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].sort(() => Math.random() - 0.5);
	return noList.map(no => {
		const randomQuestion = Math.floor(Math.random() * (2 - 0 + 1)) + 0; //최댓값도 포함, 최솟값도 포함
		return {
			no: no,
			question: data.Question[no][randomQuestion]
		}
	});
};

const TIPITest = () => {
	const [answerList, setAnswerList] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
	const [questionList, setQuestionList] = useState();
	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state;

	useEffect(() => setQuestionList(makeQuestList()), []);

	const clickAnswer = (q, a) => {
		const newArray = answerList.map(answer => answer);
		newArray[q] = a;
		setAnswerList(newArray);
	};

	const clickNext = () => {
		navigate(
			'/tipi/result',
			{
				state: {
					userInfo: state.userInfo,
					answer: answerList
				}
			}
		);
	};

	return (
		<section id={'tipi-test'}>
			<h2>10개 항목 성격 측정</h2>
			<p>아래 문항을 읽고 해당 얼마나 일치하는지 선택해주세요. 선택하는 시간이 짧을수록 정확한 테스트 결과를 얻을 수 있어요.</p>

			{questionList && questionList.map((question, i) =>
				<Question key={i} no={i + 1} questionNo={question.no} question={question.question} clickAnswer={clickAnswer} />
			)}
			<button onClick={clickNext}>다음</button>
		</section>
	);
};

const Question = (props) => {
	const no = props.no;
	const questionNo = props.questionNo;
	const question = props.question;

	return (
		<article>
			<p>{no}. {question}</p>
			<ul>
				{[1, 2, 3, 4, 5, 6, 7].map(answer => {
					return (
						<li key={answer}>
							<Answer no={no} answer={answer} onChange={() => props.clickAnswer(questionNo, answer)} />
						</li>
					);
				})}
			</ul>
		</article>
	);
};

const Answer = (props) => {
	const no = props.no;
	const answer = props.answer;
	return (
		<label>
			<input type={'radio'} name={`question_${no}`} onChange={props.onChange} /> {answer}
		</label>
	);
}

export default TIPITest;