import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './TIPIResult.css';

import ApexCharts from 'apexcharts'
import * as data from './Data';

const TIPIResult = () => {
	const refUserChart = useRef();
	const refNormalChart = useRef();
	const location = useLocation();
	const state = location.state;
	const userInfo = state.userInfo;
	const answer = state.answer;
	const average = data.TIPINorm[userInfo.gender][userInfo.age];
	const openness = calculator(answer[4], answer[9]);
	const conscientiouness = calculator(answer[2], answer[7]);
	const extroversion = calculator(answer[0], answer[5]);
	const agreeableness = calculator(answer[6], answer[1]);
	const neuroticism = calculator(answer[8], answer[3]);

	useEffect(() => {
		const userChartOptions = makeChartOption([openness, conscientiouness, extroversion, agreeableness, neuroticism]);
		const normalChartOptions = makeChartOption(average);

		(async () => {
			await new ApexCharts(refUserChart.current, userChartOptions).render();
			await new ApexCharts(refNormalChart.current, normalChartOptions).render();
		})();
	}, [openness, conscientiouness, extroversion, agreeableness, neuroticism, average]);

	return (
		<section id={'tipi-result'}>
			<p>해당 검사는 샘 고슬링 (Sam Gosling), 제이슨 랜트프로우 (Jason Rentfrow), 윌리엄 스완 (William Swann) 등이 10개의 질문으로 짧은 시간에 5가지 성격 특성 요소(Five factor model)를 파악할 수 있도록 개발한 TIPI(Ten-Item Personality Inventory) 검사를 기반으로 제작되었습니다.</p>
			<h2>5가지 성격 측정 결과</h2>
			<p>모든 성격의 점수는 높을수록 긍정적으로 평가되며 편차에 의한 평균의 최저, 최고 점수는 표시하지 않습니다.</p>

			<div className={'chart'}>
				<article>
					<strong>{userInfo.name}님의 점수</strong>
					<div ref={refUserChart}></div>
				</article>
				<article>
					<strong>{data.Age[userInfo.age]} 평균</strong>
					<div ref={refNormalChart}></div>
				</article>
			</div>

			<table id={'table-chart'}>
				<caption>{userInfo.name}의 점수 및 {data.Age[userInfo.age]} 평균</caption>
				<thead>
					<tr>
						<th>성격</th>
						<th>점수</th>
						<th>평균</th>
					</tr>
				</thead>
				<tbody>
					<tr><th>{data.personalityTitle(data.Personality.OPENNESS)}<br />(Openness)</th><th>{openness}</th><th>{average[data.Personality.OPENNESS]}</th></tr>
					<tr><th>{data.personalityTitle(data.Personality.CONSCIENTIOUNESS)}<br />(Conscientiouness)</th><th>{conscientiouness}</th><th>{average[data.Personality.CONSCIENTIOUNESS]}</th></tr>
					<tr><th>{data.personalityTitle(data.Personality.EXTROVERSION)}<br />(Extroversion)</th><th>{extroversion}</th><th>{average[data.Personality.EXTROVERSION]}</th></tr>
					<tr><th>{data.personalityTitle(data.Personality.AGREEABLENESS)}<br />(Agreeableness)</th><th>{agreeableness}</th><th>{average[data.Personality.AGREEABLENESS]}</th></tr>
					<tr><th>{data.personalityTitle(data.Personality.NEUROTICISM)}<br />(Neuroticism)</th><th>{neuroticism}</th><th>{average[data.Personality.NEUROTICISM]}</th></tr>
				</tbody>
			</table>

			<Personality name={userInfo.name} age={userInfo.age} gender={userInfo.gender} personality={data.Personality.OPENNESS} value={openness} average={average[data.Personality.OPENNESS]} />
			<Personality name={userInfo.name} age={userInfo.age} gender={userInfo.gender} personality={data.Personality.CONSCIENTIOUNESS} value={conscientiouness} average={average[data.Personality.CONSCIENTIOUNESS]} />
			<Personality name={userInfo.name} age={userInfo.age} gender={userInfo.gender} personality={data.Personality.EXTROVERSION} value={extroversion} average={average[data.Personality.EXTROVERSION]} />
			<Personality name={userInfo.name} age={userInfo.age} gender={userInfo.gender} personality={data.Personality.AGREEABLENESS} value={agreeableness} average={average[data.Personality.AGREEABLENESS]} />
			<Personality name={userInfo.name} age={userInfo.age} gender={userInfo.gender} personality={data.Personality.NEUROTICISM} value={neuroticism} average={average[data.Personality.NEUROTICISM]} />

		</section>
	);
};

const Personality = (props) => {
	const name = props.name;
	const age = props.age;
	const gender = props.gender;
	const value = props.value;
	const average = props.average;
	const personality = props.personality;

	return (
		<div className={'personality-description'}>
			<h3>{data.personalityTitle(personality)}</h3>
			<div className={'circle'}>
				<p className={'circle-desc'}>
					<span>{name}님의 {data.personalityTitle(personality)} 점수</span>
					<span>{data.Age[age]} 평균 점수</span>
				</p>
				<Circle point={data.AgreeDisagree.DISAGREE_STRONGLY} value={value} average={average} />
				<Circle point={data.AgreeDisagree.DISAGREE_MODERATELY} value={value} average={average} />
				<Circle point={data.AgreeDisagree.DISAGREE_A_LITTLE} value={value} average={average} />
				<Circle point={data.AgreeDisagree.NEITHER_AGREE_NOR_DISAGREE} value={value} average={average} />
				<Circle point={data.AgreeDisagree.AGREE_A_LITTLE} value={value} average={average} />
				<Circle point={data.AgreeDisagree.AGREE_MODERATELY} value={value} average={average} />
				<Circle point={data.AgreeDisagree.AGREE_STRONGLY} value={value} average={average} />
			</div>
			<table>
				<tbody>
					<tr>
						<th>{name} 님의 {data.personalityTitle(personality)}</th>
						<td>
							{
								data.tipiMessage(personality, gender, age, value).map((message, i) => <p key={i}>{message}</p>)
							}
						</td>
					</tr>
				</tbody>
			</table>
			<p>{data.description[personality]}</p>
		</div>
	);
};

const Circle = (props) => {
	const point = props.point;
	const value = props.value;
	const average = props.average;
	let up = 0;
	let down = 0;
	let singlePoint = point;
	let className = '';
	let singleClassName = '';

	const check = (p, v) => {
		return p <= v && v < (p + 1);
	};

	if(value < average) {
		up = average;
		down = value;
	} else {
		up = value;
		down = average;
	}

	if(check(point, average)) {
		singlePoint = average;
		singleClassName = 'average';
	} else if(check(point, value)) {
		singlePoint = value;
		singleClassName = 'value';
	}

	switch(point) {
		case data.AgreeDisagree.DISAGREE_STRONGLY: className = 'disagree-strongly'; break;
		case data.AgreeDisagree.DISAGREE_MODERATELY: className = 'disagree-moderately'; break;
		case data.AgreeDisagree.DISAGREE_A_LITTLE: className = 'disagree-a-little'; break;
		case data.AgreeDisagree.NEITHER_AGREE_NOR_DISAGREE: className = 'neither-agree-nor-disagree'; break;
		case data.AgreeDisagree.AGREE_A_LITTLE: className = 'agree-a-little'; break;
		case data.AgreeDisagree.AGREE_MODERATELY: className = 'agree-moderately'; break;
		case data.AgreeDisagree.AGREE_STRONGLY: className = 'agree-strongly'; break;
		default: return;
	}

	return (
		<>
			{ (check(point, average) && check(point, value)) &&
				<div className={`${className}`}>
					 <span className={`up ${up === average ? 'average' : 'value'}`}><strong>{up}</strong></span>
					 <span className={`down ${up === average ? 'value' : 'average'}`}><strong>{down}</strong></span>
				</div>
			}
			{ !(check(point, average) && check(point, value)) &&
				<div className={`single ${className}`}>
					 <span className={`${singleClassName}`}><strong>{singlePoint}</strong></span>
				</div>
			}
		</>
	);
};

const calculator = (value1, value2) => (value1 + (8 - value2)) / 2;

const makeChartOption = (values) => {
	return {
		chart: {
			type: 'radar',
			toolbar: { show: false },
			height: '160px',
			width: '160px'
		},
		series: [{
			name: '',
			data: values
		}],
		labels: ['O', 'C', 'E', 'A', 'N'],
		yaxis: {
			show: true,
			min: 0,
			max: 7,
			tickAmount: 7,
			labels: { show: false }
		}
	};
};

export default TIPIResult;