import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserInfo.css';
import * as data from './Data';

const UserInfo = () => {
	const navigate = useNavigate();
	const [name, setName] = useState();
	const [gender, setGender] = useState();
	const [age, setAge] = useState();

	const clickNext = () => {
		if(!name || !gender || !age) {
			alert('모든 정보를 입력하세요.');
			return;
		}

		const userInfo = {
			name:name,
			gender:gender,
			age:age
		};

		navigate(
			'/tipi/test',
			{
				state: {
					 userInfo: userInfo
				}
			}
		);
	};

	return (
		<>
			<div>
				<form>
					<dl>
						<dt><label htmlFor={'name'}>이름</label></dt>
						<dd><input id={'name'} type={'text'} onChange={(e) => setName(e.target.value)} /></dd>
					</dl>
					<dl>
						<dt>성별</dt>
						{
							data.Gender.map((gender, index) =>
								<dd key={index}>
									<label>
										<input type={'radio'} name={'gender'} value={index} onChange={(e) => setGender(e.target.value)} /> {gender}
									</label>
								</dd>
							)
						}
					</dl>
					<dl>
						<dt>연령</dt>
						{
							data.Age.map((age, index) =>
								<dd key={index}>
									<label>
										<input type={'radio'} name={'age'} value={index} onChange={(e) => setAge(e.target.value)} /> {age}
									</label>
								</dd>
							)
						}
					</dl>
				</form>
			</div>
			<button onClick={clickNext}>다음</button>
		</>
	);
};

export default UserInfo;