const Gender = [
	'남성',
	'여성'
];

const GenderValue = {
	MALE: 0,
	FEMALE: 1
};

const Age = [
	'15세 ~ 20세',
	'21세 ~ 30세',
	'31세 ~ 40세',
	'41세 ~ 50세',
	'51세 ~ 60세',
	'61세이상'
];

const AgeValue = {
	AGE_15_20: 0,
	AGE_21_30: 1,
	AGE_31_40: 2,
	AGE_41_50: 3,
	AGE_51_60: 4,
	AGE_60_: 5
};

const AgreeDisagree = {
	DISAGREE_STRONGLY: 1,
	DISAGREE_MODERATELY: 2,
	DISAGREE_A_LITTLE: 3,
	NEITHER_AGREE_NOR_DISAGREE: 4,
	AGREE_A_LITTLE: 5,
	AGREE_MODERATELY: 6,
	AGREE_STRONGLY: 7
};

const Question = [
	[
		'나는 외향적이고, 열정적이다.',
		'나는 활발하고, 열심히 하는 사람이다.',
		'나는 사교적이고, 적극적이다.'
	],
	[
		'나는 비판적이고, 논쟁을 좋아한다.',
		'나는 따지기를 좋아하고, 다투기를 잘하는 사람이다.',
		'나는 문제를 지적하기 좋아하고, 토론하기를 좋아한다.'
	],
	[
		'나는 사람들에게 신뢰를 얻고 있으며, 자기 관리가 가능하다.',
		'나는 믿음직스럽고, 자기관리가 가능한 사람이다.',
		'나는 신뢰할 수 있고, 자기 절제를 잘한다.'
	],
	[
		'나는 불안하고, 화를 잘 낸다.',
		'나는 불안하고, 화를 잘 내는 사람이다.',
		'나는 근심 걱정이 많은 편이며, 쉽게 흥분한다.'
	],
	[
		'나는 새로운 것을 경험하기 좋아하고, 생각이 복잡하다.',
		'나는 새로운 경험을 마다하지 않으며, 여러 가지로 생각해보는 사람이다.',
		'나는 새로운 경험들에 개방적이고, 복잡다단하다.'
	],
	[
		'나는 내향적이고, 조용하다.',
		'나는 내향적이고, 조용한 사람이다.',
		'나는 활동적이지 않은 편이며, 조용하다.'
	],
	[
		'나는 동정심이 많고, 다정하다.',
		'나는 동정심이 많고, 다정한 사람이다.',
		'나는 동정심이 많고, 다정다감하다.'
	],
	[
		'나는 무질서하고, 부주의하다.',
		'나는 계획적이지 않고, 조심성 없는 사람이다.',
		'나는 정리정돈을 잘 못하는 편이고, 덤벙되는 편이다.'
	],
	[
		'나는 침착하고, 정서가 안정적이다.',
		'나는 침착하고, 기분이 안정된 사람이다.',
		'나는 차분하고 감정의 기복이 적다.'
	],
	[
		'나는 변화를 싫어하고, 창조적이지 않다.',
		'나는 변화를 싫어하며, 창의적이지 않은 사람이다.',
		'나는 변화를 싫어하는 편이며, 창의적이지 못한 것 같다.'
	],
];

const Personality = {
	OPENNESS: 0,
	CONSCIENTIOUNESS: 1,
	EXTROVERSION: 2,
	AGREEABLENESS: 3,
	NEUROTICISM: 4
};

const personalityTitle = (personality) => {
	switch(personality) {
		case Personality.OPENNESS: return '경험에 대한 개방성';
		case Personality.CONSCIENTIOUNESS: return '성실성';
		case Personality.EXTROVERSION: return '외향성';
		case Personality.AGREEABLENESS: return '친화우호성';
		case Personality.NEUROTICISM: return '정서안정성';
		default: return;
	}
};

// 성별, 연령대별 성격 평균 점수.
// 배열의 다섯가지 값은 OPENNESS(0), CONSCIENTIOUNESS(1), EXTROVERSION(2), AGREEABLENESS(3), NEUROTICISM(4) 순서의 인덱스를 갖는다.
const TIPINorm = [
	[ // MALE
		[5.43, 4.41, 3.79, 4.47, 4.61], // '15세 ~ 20세'
		[5.49, 4.57, 3.73, 4.5, 4.64],  // '21세 ~ 30세'
		[5.49, 4.77, 3.81, 4.55, 4.63], // '31세 ~ 40세'
		[5.41, 4.96, 3.85, 4.7, 4.72],  // '41세 ~ 50세'
		[5.39, 5.11, 3.87, 4.89, 4.8],  // '51세 ~ 60세'
		[5.37, 5.26, 3.85, 4.95, 4.92]  // '61세이상'
	],
	[ // FEMALE
		[5.58, 4.52, 4.06, 4.73, 4.07], // '15세 ~ 20세'
		[5.55, 4.78, 4.07, 4.88, 4.09], // '21세 ~ 30세'
		[5.49, 4.97, 4.17, 5.04, 4.25], // '31세 ~ 40세'
		[5.46, 5.18, 4.2, 5.28, 4.49],  // '41세 ~ 50세'
		[5.42, 5.35, 4.18, 5.43, 4.66], // '51세 ~ 60세'
		[5.39, 5.39, 4.21, 5.5, 4.84]   // '61세이상'
	]
];

const TIPINormLow = [
	[ // MALE
		[5.43 - 1.17, 4.41 - 1.39, 3.79 - 1.55, 4.47 - 1.22, 4.61 - 1.47], // '15세 ~ 20세'
		[5.49 - 1.13, 4.57 - 1.39, 3.73 - 1.54, 4.5 - 1.2, 4.64 - 1.46],  // '21세 ~ 30세'
		[5.49 - 1.12, 4.77 - 1.35, 3.81 - 1.54, 4.55 - 1.21, 4.63 - 1.42], // '31세 ~ 40세'
		[5.41 - 1.17, 4.96 - 1.35, 3.85 - 1.54, 4.7 - 1.18, 4.72 - 1.39],  // '41세 ~ 50세'
		[5.39 - 1.2, 5.11 - 1.31, 3.87 - 1.54, 4.89 - 1.18, 4.8 - 1.38],  // '51세 ~ 60세'
		[5.37 - 1.26, 5.26 - 1.3, 3.85 - 1.49, 4.95 - 1.17, 4.92 - 1.34]  // '61세이상'
	],
	[ // FEMALE
		[5.58 - 1.1, 4.52 - 1.42, 4.06 - 1.58, 4.73 - 1.22, 4.07 - 1.46], // '15세 ~ 20세'
		[5.55 - 1.12, 4.78 - 1.41, 4.07 - 1.61, 4.88 - 1.19, 4.09 - 1.45], // '21세 ~ 30세'
		[5.49 - 1.18, 4.97 - 1.41, 4.17 - 1.64, 5.04 - 1.19, 4.25 - 1.45], // '31세 ~ 40세'
		[5.46 - 1.2, 5.18 - 1.36, 4.2 - 1.64, 5.28 - 1.17, 4.49 - 1.45],  // '41세 ~ 50세'
		[5.42 - 1.25, 5.35 - 1.31, 4.18 - 1.6, 5.43 - 1.14, 4.66 - 1.44], // '51세 ~ 60세'
		[5.39 - 1.27, 5.39 - 1.36, 4.21 - 1.62, 5.5 - 1.15, 4.84 - 1.4]   // '61세이상'
	]
];

const TIPINormHigh = [
	[ // MALE
		[5.43 + 1.17, 4.41 + 1.39, 3.79 + 1.55, 4.47 + 1.22, 4.61 + 1.47], // '15세 ~ 20세'
		[5.49 + 1.13, 4.57 + 1.39, 3.73 + 1.54, 4.5 + 1.2, 4.64 + 1.46],  // '21세 ~ 30세'
		[5.49 + 1.12, 4.77 + 1.35, 3.81 + 1.54, 4.55 + 1.21, 4.63 + 1.42], // '31세 ~ 40세'
		[5.41 + 1.17, 4.96 + 1.35, 3.85 + 1.54, 4.7 + 1.18, 4.72 + 1.39],  // '41세 ~ 50세'
		[5.39 + 1.2, 5.11 + 1.31, 3.87 + 1.54, 4.89 + 1.18, 4.8 + 1.38],  // '51세 ~ 60세'
		[5.37 + 1.26, 5.26 + 1.3, 3.85 + 1.49, 4.95 + 1.17, 4.92 + 1.34]  // '61세이상'
	],
	[ // FEMALE
		[5.58 + 1.1, 4.52 + 1.42, 4.06 + 1.58, 4.73 + 1.22, 4.07 + 1.46], // '15세 ~ 20세'
		[5.55 + 1.12, 4.78 + 1.41, 4.07 + 1.61, 4.88 + 1.19, 4.09 + 1.45], // '21세 ~ 30세'
		[5.49 + 1.18, 4.97 + 1.41, 4.17 + 1.64, 5.04 + 1.19, 4.25 + 1.45], // '31세 ~ 40세'
		[5.46 + 1.2, 5.18 + 1.36, 4.2 + 1.64, 5.28 + 1.17, 4.49 + 1.45],  // '41세 ~ 50세'
		[5.42 + 1.25, 5.35 + 1.31, 4.18 + 1.6, 5.43 + 1.14, 4.66 + 1.44], // '51세 ~ 60세'
		[5.39 + 1.27, 5.39 + 1.36, 4.21 + 1.62, 5.5 + 1.15, 4.84 + 1.4]   // '61세이상'
	]
];

const tipiMessage = (personality, gender, age, value) => {
	let low = TIPINormLow[gender][age][personality];
	let high = TIPINormHigh[gender][age][personality];

	let message = [
		['평범한 개방성'],
		['평범한 성실성'],
		['평범한 외향성'],
		['평범한 친화우호성'],
		['평범한 정서안정성']
	];

	if(value <= low) {
		message = [
			['상상력이 빈약한', '창의적이지 않은', '호기심이 적은', '생각이 얕은', '단순한'],
			['비체계적인', '무책임한', '현실적이 아닌', '부주의한', '게으른'],
			['조용한', '주장이 약한', '모험을 하지 않는', '열정적이지 않은', '소심한'],
			['불친절한', '비협조적인', '이기적인', '잘 믿지 않는', '인색한'],
			['긴장된', '불안한', '불안정한', '불만스러운', '감정적인']
		];
	} else if(high <= value) {
		message = [
			['상상력이 풍부한', '창의적인', '호기심 많은', '생각이 깊은', '세련된'],
			['체계적인', '책임감 있는', '현실적인', '철저한', '근면한'],
			['말이 많은', '주장이 강한', '모험적인', '열정적인', '대담한'],
			['친절한', '협조적인', '이기적이 아닌', '잘 믿는', '관대한'],
			['편안한', '이완된', '안정된', '만족하는', '침착한']
		];
	}

	return message[personality];
};

const description = [
	'경험에 대한 개방성은 호기심이 많고 새로운 체험을 좋아하는 열린 자세를 말합니다. 경험에 대한 개방성이 높은 사람은 독창적이고 독립적이며 예술적인 동시에 진보적입니다.  또한, 유머감각이 좋고, 이성과의 친밀한 관계맺기 능력이 탁월하지만, 그 관계를 오랫동안 지속하지 못하는 경향이 있습니다. 반면, 개방성이 낮은 사람은 인습적이며 현실적이고 전통과 권위, 안정과 질서를 좋아하며 순응적인 사람입니다.',
	'성실성은 자기통제와 조절을 잘하면서 성취를 위해 책임감을 보이는 성품을 나타냅니다. 성실성이 높다면 평균 학점도 높고 직업적 성공을 거두는 경향이 있으며, 인간관계의 만족도와 안정도가 높습니다. 다만, 지나치게 성실성이 높은 사람이라면 자기 기준을 고집하여 오히려 인간관계에서 갈등을 자주 경험할 수도 있습니다. 반면, 성실성이 낮은 사람은 산만하고 일관성이 없으며 나태합니다. 이런 사람들은 여유롭고 시원스러운 점이 매력적으로 보일 때도 있지만, 책임감의 부족으로 인해 장기적으로는 신뢰있는 인간관계를 유지하지 못하는 이들입니다.',
	'외향성은 다른 사람과의 교류를 통해 인간관계적 자극을 추구하려는 성향을 말합니다. 외향성이 높은 사람은 낯선 사람에게도 스스럼없이 다가가서 쉽게 대화를 나누고, 더 먼저 말하고 더 많이 말합니다. 그래서 이런 사람들은 다양한 사람들과 넓은 인간관계를 맺으며, 새로운 도전을 좋아하기 때문에 성공도 많이 하지만 실패도 많이 합니다. 그래서 극단적으로 외향성이 높은 사람이라면 지루함을 견디지 못하기 때문에 폭음이나 도박, 이혼의 경향이 존재합니다.',
	'친화우호성은 타인을 적대적으로 여기지 않는 성품으로, 우호적이며 협동적으로 타인을 대하기에 인간관계의 질을 높여주는 성격요인입니다. 우호적이고 친화성이 높은 사람은 따뜻하고 부드러우며 공감적인 동시에 인간관계의 만족도와 결혼생활 안정도가 높은 경향을 보입니다. 반면에 친화우호성이 떨어지는 사람은 타인의 감정을 이해하는 공감능력이 부족하고 때로는 적대적인 성향을 보일 수 있습니다. 또한, 자기주장과 자기이익을 내세우며 잘 확보하려는 경향을 보입니다.',
	'정서안정성은 분노나 우울, 불안과 같은 부적응적 정서를 잘 느끼는 경향을 말합니다. 적응하지 못하기에 정서적으로 예민하고 불안정합니다. 그래서 사소한 일에도 쉽게 상처받고, 우울이나 불안 같은 불쾌한 정서를 쉽게 느끼면서 스트레스를 잘 받고 변덕이 심합니다. 신경이 예민한 사람들은 인간관계에서도 갈등과 불화를 잘 겪습니다. 한마디로 개인적 부적응이 관계적 부적응으로 이어지는 것 입니다. 반면, 정서안정성이 사람들은 실패의 예방을 잘하고 직업적 성공을 거두며 심리적 성숙을 위해 더 많은 노력을 보이기도 합니다.'
];



export { Age, AgeValue, Gender, GenderValue, AgreeDisagree, Question, Personality, TIPINorm, TIPINormLow, TIPINormHigh, tipiMessage, description, personalityTitle }